import React, { useState ,useContext, useEffect} from "react";

import axios from "axios";

import { UserContext } from "../login/login-context";
import { useNavigate } from "react-router-dom";

import { Formaccount } from "./config_profile.js";
import { HomePost } from "./home_post.js";
import { NavBarSup } from "../navbar/navbarsup.js";
import Fotr_log from "../Footer/footerlog";

// import "../workspace/Work.css";
import "../Profile/profile.css";
import "./workspace.css";


// import "react-calendar/dist/Calendar.css";
function Workspacehome() {
    const [expandedmobilebutton, setExpandedmobilebutton] = useState(false);
    const [selectedPestana, setSelectedPestana] = useState("home");
    const eMobile = () => {
        setExpandedmobilebutton(!expandedmobilebutton);

      };
      const navigate = useNavigate();
        // eslint-disable-next-line no-unused-vars
        const [user, setUser] = useContext(UserContext);
        // eslint-disable-next-line no-unused-vars
        const [loggedIn, setLoggedIn] = useState(false);
        const [profilelink, setProfilelink] = useState("");
        
      
        useEffect(() => {
   
          if (user && user.logged_in && user.profile_link) {
            setLoggedIn(true);
            setProfilelink(user.profile_link)
          } else {
            setLoggedIn(false);
            setProfilelink("")

          }
        }, [user]);


        useEffect(() => {
          if (!(user && user.logged_in)) {
            // navigate("/home" + user.profile_link);
            navigate("/");
          }
        }, [user]);
        const [to_profile_first,setToprofile]=useState("")

        useEffect(() => {
          if (to_profile_first==="true") {
            // navigate("/home" + user.profile_link);
            const url_path="/ps/"+profilelink
            console.log(info["first_time_login"])
            navigate(url_path);
          }
        }, [to_profile_first]);

 
const [info,setData]=useState({})

 useEffect(() => {
  getData();
}, []);

// Función para hacer GET
const getData = async () => {
  try {
      const response = await axios.get('/api/home_feeder');
      setData(response.data);
      setToprofile(response.data["first_time_login"]===false?"false":"true")
      console.log(response.data)
  } catch (error) {
      console.error('Error al hacer GET:', error);
  }
};

  return (
    <div
      style={{
        width: "100%",
        background: "#f7f7f7",
        height: "100%",
      }}
    >
     
      <div className="row no-gutters perfilcontenedorwk">
      <NavBarSup  profile="false"/>
        <div className="col-lg-2">
            <div className="izqwk">
                <div className="buttonmobile"><a onClick={eMobile}><i className="fa-solid fa-bars fawks"></i></a></div>
          <div className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}> 
          <a className="linkwkspc" onClick={(e) => { e.preventDefault(); setSelectedPestana('home');setExpandedmobilebutton(false)}}>
          <i className="fa-solid fa-house fawks" style={{color: selectedPestana === "home" ? "#FFC97D" : "white"}}></i>
          <p className="txtwk"  style={{color: selectedPestana === "home" ? "#FFC97D" : "white"}}>
          Home
          </p></a>
          </div>
          <div className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}> 
         <a className="linkwkspc" href={`/ps/${profilelink?profilelink:""}`}> <i className="fa-solid fa-user fawks"></i>
          <p className="txtwk">
          Perfil
          </p></a>
          </div>
          <div className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`} > 
          <i className="fa-solid fa-calendar fawks"></i>
          <p className="txtwk">
          Agenda <b style={{fontSize:"10px", alignSelf:"center", color:"#FFC97D"}}> próximamente</b>
          </p>
          </div>
          <div className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}> 
          <i className="fa-solid fa-envelope fawks"></i>
          <p className="txtwk">
          Mensajes <b style={{fontSize:"10px", alignSelf:"center", color:"#FFC97D"}}> próximamente</b>
          </p>
          </div>

          <div className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}> 
          <a className="linkwkspc" onClick={(e) => { e.preventDefault(); setSelectedPestana('config');setExpandedmobilebutton(false)}}> <i className="fa-solid fa-gear fawks" style={{color: selectedPestana === "config" ? "#FFC97D" : "white"}}></i>
          <p className="txtwk"  style={{color: selectedPestana === "config" ? "#FFC97D" : "white"}}>
          Configuración
          </p></a>
          </div>
        </div>
        </div>

        <div className="col-lg-10 perfilcentralwk" >
        <article  style={{display: selectedPestana === "config" ? "block" : "none"}}> <Formaccount name={info["name"]} lastname={info["lastname"]} email={info["email"]}/> </article>
           
       


          <div  style={{display: selectedPestana === "home" ? "block" : "none"}}><HomePost name={info["name"]}/></div>
       </div>

      </div>
 
      <Fotr_log />
    </div>
  );
}

export default Workspacehome;
