import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

function findInputError(errors, name) {
  const filtered = Object.keys(errors)
    .filter((key) => key.includes(name))
    .reduce((cur, key) => {
      return Object.assign(cur, { error: errors[key] });
    }, {});
  return filtered;
}

const isFormInvalid = (err) => {
  if (Object.keys(err).length > 0) return true;
  return false;
};

export const Input = ({ label, type, id, placeholder, validation, name }) => {
  Input.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    validation: PropTypes.any,
    name: PropTypes.string,
  };
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputError = findInputError(errors, label);
  const isInvalid = isFormInvalid(inputError);

  return (
    <div className="mb-3">
      <input
        id={id}
        type={type}
        className={"form-control " + (isInvalid ? "is-invalid" : "")}
        placeholder={placeholder}
        {...register(name, validation)}
      />
      {isInvalid && (
        <div className="invalid-feedback">{inputError.error.message}</div>
      )}
    </div>
  );
};
