import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import Modal_ from "../Modals/Modal_";
import Logo from "./../img/LogoVincularse.png";
import { UserContext } from "../login/login-context";

export function ProfileModal({first_time_login, session}) {
  ProfileModal.propTypes = {
    first_time_login: PropTypes.bool,
    session: PropTypes.bool,
  }
  // eslint-disable-next-line no-unused-vars
  const [ user, setUser ] = useContext(UserContext);

  const [openFtlModal, setFtlModal] = useState(false);
  const [ftlMessage, setFtlMessage] = useState(false);
  const [validateMessage, setValidateMessage] = useState(false);
  const closeFtlModal = () => {
    setFtlModal(false);
  }
  const ftlModalRef = useRef();
  useEffect(() => {
    if (ftlModalRef.current && first_time_login == true && session) {
      setFtlMessage(true);
      setFtlModal(true);
    }
    if (session && user) {
      axios.get(`/api/is_validated/${user.guid}`)
        .then()
        .catch((error) => {
          if (error.response.status == 400) {
            setValidateMessage(true);
            setFtlModal(true);
          }
        })
    }
  }, [user, session, first_time_login])
  useEffect(() => {
    if (openFtlModal) {
      ftlModalRef.current.click();
    }
  }, [openFtlModal])

  const [sentMessage, setSentMessage] = useState("");
  const sendConfirmationEmail = () => {
    axios.get(`/api/send_validation_email`, { withCredentials: true })
      .then(() => {
        setSentMessage("Validación enviada al correo");
      })
      .catch(() => {
        setSentMessage("Intenta nuevamente");
      })
  }

  return (
    <div>
      <a
        href="#"
        data-toggle="modal"
        data-target="#firstTimeLoginModal"
        ref={ftlModalRef}
      ></a>
      <Modal_
        isOpen={openFtlModal}
        id="firstTimeLoginModal"
        onClose={closeFtlModal}
      >
        <div className="modal-header">
        <img
            className="imghead"
            href="/"
            src={Logo}
            alt="Vinculandose Logo"
            style={{ maxWidth: "50%" }}
        />

        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div>
        {ftlMessage && (
          <p>Por favor recuerda completar tu perfil</p>
        )}
        {validateMessage && (
          <div>
            <p>Recuerda confirmar tu correo para aparecer en las busquedas</p>
            <button type="button" className="button-login" onClick={sendConfirmationEmail} style={{ 'fontSize': '1.2em' }}>Enviar correo de confirmación</button>
            <p>{sentMessage}</p>
          </div>
        )}
        </div>
      </Modal_>
    </div>
  )
}
