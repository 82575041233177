import React from "react";
import NavBar from "../navbar/navbar";

const CompleteRegistration = () => {
  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />
      <div className="centerin2">
        <h1 className="fuenteh2" style={{ marginLeft: "0" }}>
          Gracias por tu registro
        </h1>
        <article className="fuente2">
          <p>Hemos enviado un enlace de confirmación a tu correo.</p>
          <p>Si no puedes visualizar tu correo busca en tu bandeja de spam.</p>
        </article>
      </div>
    </div>
  );
};

export default CompleteRegistration;
