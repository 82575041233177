import React, { useState} from "react";
import "../navbar/NavBar.css"; // Asegúrate de tener tu archivo de estilos CSS
import "../../App.css";


// eslint-disable-next-line react/prop-types
export function NavBarSup2 () {


  
  

  const [preview, setPreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };



  return (
    <nav className="navbar_profiles">
      <div className="navbar-brand_profiles">
        <a href="/">
        {preview && (
          <div>
            <img 
         
            src={preview} alt="Previsualización de la imagen" style={{ maxWidth: '50%', height: 'auto' }} />
          </div>
        )}


        </a>
      </div>



      {/* Mostrar la lista de navegación en pantallas grandes */}




      <input type="file" accept="image/*" onChange={handleImageChange} style={{width:"10%"}}/>


        {/* <li className="deshorizontal">
          <a
            href="/nosotros"
            className="boton-enlace"
            style={{ textShadow: "2px 2px 4px #a0a0a0" }}
          >
            Nosotros
          </a>
        </li> */}
     

        {/* <li className="deshorizontal">
          <a
            href="/contacto"
            className="boton-enlace"
            style={{ textShadow: "2px 2px 4px #a0a0a0" }}
          >
            Contacto
          </a>
        </li> */}


    </nav>
  );
}
