import React from "react";

import Image from "../img/notfound.jpeg";
// import Image from "./img/448ab3.png";



const Notfound = () => {


 



  return (
    <div className="row no-gutters notfound">
        <div className="col-lg-7" >
        <img src={Image} style={{height:"80%", borderRadius:"50%"}} alt="a" />
          </div>
          <div className="col-lg-5" >
          <article className="fuentenotfound">
                <div>
                UPS...  ...404
                </div>

                <div>No hemos encontrado lo que buscas</div>
                <div>Por favor vuelve a intentarlo</div>

              </article>
          </div>
          </div>


      
            
              

  );
};
export default Notfound;
