import React from "react";

import "../../App.css";
import "./footer.css";
import LogoC from "../img/LogoHeader.png";

function Fotr() {
  return (
    <div className="container-fluid">
      <footer className="text-center text-lg-start bg-body-tertiary text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div style={{ margin: "0 auto" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/vincular_se"
              className="me-4 text-reset"
            >
              <i className="fab fa-instagram" style={{ fontSize: "25px" }}></i>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/vinculando-se"
              className="me-4 text-reset"
            >
              <i className="fab fa-linkedin" style={{ fontSize: "25px" }}></i>
            </a>
          </div>
        </section>

        <section className="jeje">
          <div
            className="container text-center text-md-start mt-5"
            style={{ width: "100%", margin: "0 auto" }}
          >
            <div className="row">
              <div className="col-md-3 col-lg-4 col-xl-3 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img
                    href="/"
                    src={LogoC}
                    style={{ width: "25px", margin: "0px" }}
                    alt="Descripción de la imagen"
                  />{" "}
                  vincularSE
                </h6>
                <p>
                  Psicólogos y psicólogas online y presencial. Seleccionados
                  especialmente para tu proceso terapéutico.
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Links</h6>
                <p>
                  <a href="/faq" className="text-reset">
                    Preguntas Frecuentes
                  </a>
                </p>
                <p>
                  <a href="/nosotros" className="text-reset">
                    Nosotros
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Términos y condiciones
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Ayuda
                  </a>
                </p>
              </div>

              <div className="col-md-6 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
                <p>
                  <i className="fas fa-envelope me-3"></i>
                  vincular.secontact@gmail.com
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> + 56 9 62420102
                </p>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
}

export default Fotr;
