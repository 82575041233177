/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";

// import "../workspace/Work.css";

import axios from "axios";
import "./sectionshome.css";
// import "../workspace/Work.css";

export function HomeSectionA() {
  const [registers, setRegister] = useState();
  const [diagnostics, setDiagnostics] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/homefeeder`);
        setRegister(response.data["regsister"]);
        setDiagnostics(response.data["diagnostics"]);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="homecontedor">
    <div className="row no-gutters secrtionrectanglehome ">  

      <div className="col-sm-6">
        <article className="numbershome">
          
          <p className="estadistcnumberhome">{registers} </p>
          <p className="descriptionnumbershome">Profesionales registrados</p>
        </article>
      </div>
      <div className="col-sm-6">
        <article className="numbershome">
          
          <p className="estadistcnumberhome">{diagnostics} </p>
          <p className="descriptionnumbershome">
           Diagnósticos
          </p>
        </article>
      </div>

    </div>
    </section>
  );
}
