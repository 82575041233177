import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import NavBar from "../navbar/navbar";

const Validation = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const token = queryParameters.get("token");
  const [isValidated, setIsValidated] = useState(false);
  axios
    .post("/api/email_validation/" + id + "/" + token)
    .then(() => {
      setIsValidated(true);
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  const navigate = useNavigate();
  useEffect(() => {
    if (isValidated) {
      setTimeout(() => {
        navigate("/action/login");
      }, 3000);
    }
  }, [isValidated])

  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />
      <div className="centerin2">
        <h1 className="fuenteh2" style={{ marginLeft: "0" }}>
          Email validado exitosamente
        </h1>
        <article className="fuente2">
          <p>Espera un momento para tu primer login.</p>
        </article>
      </div>
    </div>
  );
};

export default Validation;
