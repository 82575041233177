
/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */
import React, {useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
// import "../workspace/Work.css";
import "./profile.css";
import "./onlinepresencial";



export function Personal({
  lgbt:initial_lgbt,
  spiritual:initial_spiritual,
  feminism:initial_feminism,
  religion:initial_religion,
  neurodivergent:initial_neurodivergent,
  native:initial_native,
  session}) 
  {
    // Personal.propTypes = {
    //   lgbt: PropTypes.string,
    //   spiritual: PropTypes.string,
    //   feminism: PropTypes.string,
    //   religion:PropTypes.string,
    //   neurodivergent:PropTypes.string,
    //   session: PropTypes.bool,
    // }
  
 
  useEffect(() => {
    setLgbt(initial_lgbt);
  }, [initial_lgbt ]);

  useEffect(() => {
    setFeminism(initial_feminism);
  
  }, [initial_feminism ]);

  useEffect(() => {
    setReligion(initial_religion);

  }, [initial_religion ]);

 useEffect(() => {
    setNative(initial_native);

  }, [initial_native ]);

  useEffect(() => {
    setNeurodivergent(initial_neurodivergent);

  }, [initial_neurodivergent ]);
  

  useEffect(() => {
    setSpiritual(initial_spiritual);

  }, [initial_neurodivergent ]);



  const [lgbt,setLgbt]=useState(initial_lgbt)
  const [feminism,setFeminism]=useState(initial_feminism)
  const [spiritual,setSpiritual]=useState(initial_spiritual)

  const [religion,setReligion]=useState(initial_religion)
  const [native,setNative]=useState(initial_native)
  const [neurodivergent,setNeurodivergent]=useState(initial_neurodivergent)
  
  
 




  const handleOnlineClick = () => {
    setShow(!show);

  };
  const [show, setShow] = useState(true);

    const { user_id } = useParams();
    
    const [editarinfo, setEditarinfo] = useState(false);


    const eInfo = () => {
      setEditarinfo(true);

    };


    const saveData = () => {
    // update_info({approach: "Psicoanálisis"}, setEnfoque, enfoque);
    //  update_info({title: "Infantil"}, setEdad, edad);
      // update_info({lgbt: lgbt_array.join(",")}, setLgbt, lgbt);
      update_info({lgbt:lgbt},setLgbt,lgbt)
      update_info({feminism:feminism},setFeminism,feminism)
      update_info({religion:religion},setReligion,religion)
      update_info({spiritual: spiritual}, setSpiritual, spiritual);
      update_info({native: native}, setNative, native);
      update_info({neurodivergent: neurodivergent}, setNeurodivergent, neurodivergent);
      setEditarinfo(false);
    }
    const update_info = (info, setData, data) => {
      axios
        .post("/api/update_user_info", info,
          {
            withCredentials: true,
            headers: { 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') },
        })
        .then((response) => {
          console.log(response);
          setData(data);

        })
        .catch((error) => console.log(error));
    }

      return (
<div>
  <div className={`${session ? "show2" : "notshow"} `} style={{display:"flex", justifyContent:"space-between"}}>
<h5> <i className="fa-solid fa-lock"></i> Información Privada</h5>
  <h5> <i className={`fa-solid fa-pen ${session ? "show" : "notshow"} `} style={{fontSize:'13px'}} onClick={() => {eInfo()}} ></i></h5></div>
<p className={`indications ${editarinfo ? "show2" : "show2"}`} > Algunos profesionales pueden sentirse más cómodos con personas que tengan preferencias personales afines.
</p>

<p className={`indications ${editarinfo ? "show2" : "show2"}`} > Esta información es privada. Solo se va a considerar en los casos que los pacientes lo soliciten de forma explicita.
</p>
<p className={`indications ${editarinfo ? "show2" : "show2"}`} > Si quieres omitir, puedes no seleccionar una opción.
</p>

  <div style={{padding:"15px"}} className="row no-gutters personalcontendor">
  <div className="col-lg-6" > 


  <div className="encabezadospersonal" > <div className="">
  <h5 className="h5persona-profile"><i className="fa-solid fa-rainbow"></i> Comunidad LGBT</h5></div>
 
        </div>
    <p className={`${editarinfo ? "notshow" : "show2"}`} style={{marginLeft:"10px"}}> <i className="fa-solid fa-o"></i> {lgbt}</p>
                <p className={`${editarinfo ? "show2" : "notshow"}`} >  <i className="fa-solid fa-o"></i> 
    
    
    <select className="selectpersonal" id="lgbt" name="lgbt" onChange={(e) => setLgbt(e.target.value,lgbt)} value={lgbt || ""} >
    <option value="No pertenezco a la comunidad">No pertenezco a la comunidad</option>
    <option value="Pertenezco a la comunidad">Pertenezco a la comunidad</option>
  </select></p>


  
  <div className="encabezadospersonal" > <div className="">
    
  <h5 className="h5persona-profile" ><i className="fa-solid fa-venus"></i> Feminismo y enfoque de género</h5>

  </div>
  <div
          className={`tglbutton ${editarinfo ? "show2" : "notshow"}`} 
         
        >
         
        </div>
        </div>
                <p className={`${editarinfo ? "notshow" : "show2"}`} style={{marginLeft:"10px"}} > <i className="fa-solid fa-o"></i> {feminism}</p>
                <p className={`${editarinfo ? "show2" : "notshow"}`} >  <i className="fa-solid fa-o"></i>  
                
                <select className="selectpersonal" id="fem" name="fem" onChange={(e) => setFeminism(e.target.value,feminism)} value={feminism || ""} >

    <option value="Feminista y atención con enfoque de género">Feminista y atención con enfoque de género</option>
    <option value="Feminista y atención sin enfoque de género">Feminista y atención sin enfoque de género</option>
    <option value="No me considero feminista">No me considero feminista</option>



  </select></p>




  <div className="encabezadospersonal" > <div className="">
    <h5 className="h5persona-profile" ><i className="fa-solid fa-church"></i> Religión</h5></div>
    <div
          className={`tglbutton ${editarinfo ? "show2" : "notshow"}`} 
         
        >
         
        </div>
        </div>
   <p className={`${editarinfo ? "notshow" : "show2"}`} style={{marginLeft:"10px"}}>  <i className="fa-solid fa-o"></i> {religion}</p>
   <p className={`${editarinfo ? "show2" : "notshow"}`} >  <i className="fa-solid fa-o"></i> 
    <select className="selectpersonal" id="religion" name="religion" onChange={(e) => setReligion(e.target.value,religion)} value={religion || ""} >

    <option value="Católico">Católico</option>
    <option value="Evangélico">Evangélico</option>
    <option value="Testigo de Jehova">Testigo de Jehova</option>
    <option value="Mormón">Mormón</option>
    <option value="Judío">Judío</option>
    <option value="Musulmán">Musulmán</option>
    <option value="Hindú">Hindú</option>
    <option value="Ateo">Ateo</option>
    <option value="Agnóstico">Agnóstico</option>


  </select></p>
  </div>
  <div className="col-lg-6" >

  <div className="encabezadospersonal" > <div className="">
    
  
   <h5 className="h5persona-profile" ><i className="fa-solid fa-person-praying"></i> Espiritualidad</h5>

   </div>

        </div>
   <p className={`${editarinfo ? "notshow" : "show2"}`} style={{marginLeft:"10px"}}>  <i className="fa-solid fa-o"></i> {spiritual}</p>
   <p className={`${editarinfo ? "show2" : "notshow"}`} >  <i className="fa-solid fa-o"></i>  
   <select className="selectpersonal" id="espir" name="espir"onChange={(e) => setSpiritual(e.target.value,spiritual)} value={spiritual || ""} >

    <option value="Me considero una persona espiritual">Me considero una persona espiritual</option>
    <option value="No me considero una persona espiritual">No me considero una persona espiritual</option>



  </select></p>

  <div className="encabezadospersonal" > <div className="">           
  <h5 className="h5persona-profile" ><i className="fa-solid fa-spinner"></i> Neurodivergencia</h5>
  </div>

        </div>



            <p className={`${editarinfo ? "notshow" : "show2"}`} style={{marginLeft:"10px"}}> <i className="fa-solid fa-o"></i> {neurodivergent}</p>
            <p className={`${editarinfo ? "show2" : "notshow"}`} >  <i className="fa-solid fa-o"></i>  
            
            <select  className="selectpersonal" id="neuro" name="neuro" onChange={(e) => setNeurodivergent(e.target.value,neurodivergent)} value={neurodivergent || ""} >
    <option value="Neurotípico">Neurotípico</option>
    <option value="Neurodivergente">Neurodivergente</option>

  </select></p>
         
          
  <div className="encabezadospersonal" > <div className="">
            <h5 className="h5persona-profile"> <i className="fa-solid fa-mountain-sun"></i> Pueblos Originarios</h5> </div>

        </div>


            
            <p className={`${editarinfo ? "notshow" : "show"}`} style={{marginLeft:"10px"}}> <i className="fa-solid fa-o"></i> {native}</p>
            <p className={`${editarinfo ? "show2" : "notshow"}`} >  <i className="fa-solid fa-o"></i>
            
            <select className="selectpersonal" id="pueblo<" name="pueblo" onChange={(e) => setNative(e.target.value,native)} value={native || ""} >
           <option value="No pertenezco a un pueblo originario">No pertenezco a un pueblo originario</option>
    <option value="Pertenezco a un pueblo originario">Pertenezco a un pueblo originario</option>
    </select></p>
            </div>
            </div>

            <div style={{textAlign:"right"}} className={`tglbutton ${editarinfo ? "show2" : "notshow"}`} ><button  onClick={saveData}>

Guardar
</button></div>
            </div>


  );
}




