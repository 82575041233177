import React, { useState, useRef } from 'react';
import ImageCropper from '../imgcrop/ImageCropper';

import axios from "axios";
import Cookies from "js-cookie";



// eslint-disable-next-line react/prop-types
export function  CROP ({imgprof:initiaimgprof}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadingImage,setUpload] =useState(false);
  // eslint-disable-next-line no-unused-vars
  const [image,setImg] =useState(initiaimgprof);
  const [croppedImage, setCroppedImage] = useState(null);

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSelectedImage(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }
  };



  const onCropComplete = (croppedImage) => {
    setCroppedImage(croppedImage);
    setUpload(false);
    
    if (croppedImage) {      
     
      
      return  update_info({profile_picture: croppedImage})}

  };
  const update_info = (info) => {

    axios
      .post("/api/update_user_info", info,
        {
          withCredentials: true,
          headers: { 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') },
      })
      .then((response) => {
        console.log(response);



      })
      .catch((error) => console.log(error));
  }


  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
    setUpload(true)
  };




  return (
    <div >
   

      <input type="file" accept="image/*" onChange={onSelectFile}  style={{ display: 'none' }} ref={fileInputRef} />
      {selectedImage && uploadingImage &&  (
        <ImageCropper imageSrc={selectedImage} onCropComplete={onCropComplete} />
      )}
   {!(uploadingImage)&&
       <img
          className="imghead epimghead"
          
          src={croppedImage ? croppedImage : image}
          alt="Descripción de la imagen" />}
          <p style={{textDecoration:"underline"}} onClick={handleIconClick}><i className="fa-solid fa-camera" ></i> Cargar Foto</p>
    </div>
 
      


  );
}

