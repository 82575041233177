import { useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { UserContext } from "../login/login-context";

function Dashboard() {

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext)

  const logOut = () => {    
    Cookies.remove('logged_in');
    Cookies.remove('user');
    Cookies.remove('csrf_access_token');
    Cookies.remove('token');
    setUser({logged_in: false});
    // Redirige al usuario a la página de inicio
    window.location.href = '/';     
  };

  const [userList, setUserList] = useState([]);
  const [lastActivity, setLastActivity] = useState([]);

  const getUserList = async () => {
    try {
      const response = await axios.get('/api/get_user_list');
      setUserList(response.data);
    } catch (error) {
      console.log("ERROR");
    }
  };

  const getUserActivity = async () => {
    try {
      const response = await axios
        .post(
          '/api/get_last_activity', 
          { "guids": userList.map((item) => item.user_guid)},
          {
            withCredentials: true,
            headers: { 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') },
        });
      setLastActivity(response.data);
    } catch (error) {
      console.log("ERROR");
    }
  }

  return (
    <div>
        <h1>Dashboard</h1>
        <button onClick={() => {logOut()}}>Logout</button>
        <button onClick={() => {getUserList()}}>Load User List</button>
        <button onClick={() => {getUserActivity()}}>Load Last User Activity</button>
        <ol>
          {(userList.map((item, idx) => <li key={idx}><a href={"https://www.vincular.se/ps/" + item.link}>{item.name} {item.last_name}</a></li>))}
        </ol>
        <ol>
          {(lastActivity.map((item, idx) => <li key={idx}>{item.email} - {item.activity_name} - {item.activity_timestamp}</li>))}
        </ol>
    </div>
  );
}

export default Dashboard;
