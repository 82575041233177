import React, { useEffect, useState } from "react";
import NavBar from "../navbar/navbar";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { email_validation, password_validation } from "./validators";
import { Input } from "./Input";

const RecoverPassword = () => {
  const [formSent, setFormSent] = useState(false);

  const methods = useForm();

  const onSubmit = methods.handleSubmit((data) => {
    axios.post("/api/recover", data);
    setFormSent(true);
  });

  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />
      <div className="centerin2">
        <h1 className="fuenteh2" style={{ marginLeft: "0" }}>
          Recuperar Contraseña
        </h1>
        <div>
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => e.preventDefault()}
              noValidate
              className="login-form"
            >
              <Input {...email_validation} />

              <button type="button" className="button-login" onClick={onSubmit}>
                Enviar
              </button>
            </form>
          </FormProvider>
        </div>
        {formSent && (
          <p>Se ha enviado un correo para recuperar su contraseña</p>
        )}
      </div>
    </div>
  );
};

export const SetPassword = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const token = queryParameters.get("token");

  const methods = useForm();
  const [isUpdated, setIsUpdated] = useState(false);

  const onSubmit = methods.handleSubmit((data) => {
    axios
      .post("/api/update_password/" + id + "/" + token, data)
      .then(() => setIsUpdated(true))
      .catch(() => setIsUpdated(false));
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        navigate("/action/login");
      }, 3000);
    }
  }, [isUpdated])

  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />
      <div className="centerin2">
        <h1 className="fuenteh2" style={{ marginLeft: "0" }}>
          Recuperar Contraseña
        </h1>
        <div>
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => e.preventDefault()}
              noValidate
              className="login-form"
            >
              <Input {...password_validation} />

              <button type="button" className="button-login" onClick={onSubmit}>
                Cambiar Contraseña
              </button>
            </form>
          </FormProvider>
        </div>
        {isUpdated && <p>Contraseña actualizada correctamente</p>}
      </div>
    </div>
  );
};

export default RecoverPassword;
