import React, { useState } from "react";
// import "../workspace/Work.css";
import "./onlinepresencial.css";
import "./profile.css";
// import Weekly from "../agenda-calendario/Calendarioweek";
import {Services} from "./services"

// eslint-disable-next-line react/prop-types
export function Onlinepresencial({ Nombre, apellido, session }) {
  // eslint-disable-next-line no-unused-vars
  const [isOnline, setIsOnline] = useState(true);


  return (
 
      <article className="onlinepresencial">
          
          <h5 className="title-sectionprofile pref redh5"> Prestaciones</h5>
          <div className="service-title">

 
        </div>
        <article className="calendarcontainer">
          <Services session={session } modality={isOnline} nombre={Nombre} apellido={apellido}/>
        </article>

        
            
      </article>
      

  );
}



