import React from "react";

import "../../App.css";
import "./footer.css";

function Fotr_log() {
  return (
    <div className="container-fluid">
      <footer className="text-center text-lg-start bg-body-tertiary text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div style={{ margin: "0 auto" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/vinculando.se"
              className="me-4 text-reset"
            >
              <i className="fab fa-instagram" style={{ fontSize: "25px" }}></i>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/vinculando-se"
              className="me-4 text-reset"
            >
              <i className="fab fa-linkedin" style={{ fontSize: "25px" }}></i>
            </a>
          </div>
        </section>

      </footer>
    </div>
  );
}

export default Fotr_log;
