import React from "react";
import PropTypes from "prop-types";
import "./Modals.css";

const Modal = ({ isOpen, id, children }) => {
  return (
    isOpen && (
      <div
        className={"modal fade"}
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{margin:"0 auto"}}>{children}</div>
        </div>
      </div>
    )
  );
};
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
