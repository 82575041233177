import comunidad from "../img/Comunidad.png";

export const Post = () => {
  

  return (
    <article className="boxwk">

  <div className="Postwksp">
  <p className="posttext">En Vincularse, sabemos que trabajar como independiente es difícil. Es por eso que hemos decidido crear esta comunidad profesional con el objetivo de apoyarte. 
    Divulgaremos cursos, webinars, actividades, supervisiones y varias cosas más.
  </p>
  <article className="imgcontainer">
  <img
    className="imghead"
    href="/"
    src={comunidad}
    alt="Logo Vinculandose"
  />
  </article>
  
<div className="actionbutton">
<a
        target="_blank"
        rel="noopener noreferrer"
        href="https://wa.me/56962420102"
        className="me-4 text-reset"
      >  <button>Quiero saber más</button></a>
</div>

  </div>
    </article>
  );
};
