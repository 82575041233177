/* eslint-disable no-unused-vars */
// ImageCropper.js
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import "../Profile/profile.css";
import Resizer from 'react-image-file-resizer';


// eslint-disable-next-line react/prop-types
const ImageCropper = ({ imageSrc, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: -10, y: 0 });
  const [zoom, setZoom] = useState(1.25);
  // eslint-disable-next-line no-unused-vars
  const [cropSize, setCropSize] = useState({width:248,height:248});
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imgUpload, setImgupload] = useState(null);


  const onCropChange = (crop) => {
    setCrop(crop);
  };



  const onCropCompleteCallback = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCropComplete = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    await new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        croppedImage,
        750, // max width
        750, // max height
        'JPEG', // format
        70, // quality
        0, // rotation
        (uri) => {
          // `uri` es la imagen redimensionada en formato base64
          setImgupload(uri);
          resolve(uri); // Resolver la promesa con el valor de `uri`
        },
        'base64'
      );
    }).then((resizedImg) => {
      console.log(resizedImg, croppedImage);
      onCropComplete(resizedImg); // Llamar a `onCropComplete` con la imagen redimensionada
    }).catch((error) => {
      console.error("Error resizing image:", error);
    });
  };
  



  return (
    <div>
      <div
        className="crop-container"
        style={{ position: 'relative', width: 250, height: 330 , margin:"0 auto"}}

      >
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          cropSize={cropSize}
          aspect={1/ 1}
          onCropChange={onCropChange}
          onZoomChange={setZoom}
          onCropComplete={onCropCompleteCallback}
        />
      </div>
      <div className="controls">
      <button className={`button-login profilebutton `}
               style={{textDecoration:"underline"}}
               onClick={handleCropComplete}> 
                <i  className={`fa-solid fa-floppy-disk `}
                
                >
                  </i> Guardar cambios</button>
       

      </div>
    </div>
  );
};

export default ImageCropper;
